// extracted by mini-css-extract-plugin
export var cardOverride = "lab-consumables-module--cardOverride--8iMB2";
export var cardWrapper = "lab-consumables-module--cardWrapper--QmDLc";
export var integrateYourResearchMargin = "lab-consumables-module--integrateYourResearchMargin--2JEdQ";
export var integrateYourResearchWrapper = "lab-consumables-module--integrateYourResearchWrapper--eYPKH";
export var itemVariantOverride = "lab-consumables-module--itemVariantOverride--1I-9U";
export var labConsumablesCardsMargin = "lab-consumables-module--labConsumablesCardsMargin--VgxMU";
export var labConsumablesWrapper = "lab-consumables-module--labConsumablesWrapper--JXV9D";
export var labConsumables_Container = "lab-consumables-module--labConsumables_Container--ouWAj";
export var layout = "lab-consumables-module--layout--WFXQt";
export var limitWidthOnLargeScreens = "lab-consumables-module--limitWidthOnLargeScreens--DtFI8";
export var link = "lab-consumables-module--link--FZ6GX";
export var listClassNameOverride = "lab-consumables-module--listClassNameOverride--ErShU";
export var sectionsMargin = "lab-consumables-module--sectionsMargin--GDVDV";
export var titleOverride = "lab-consumables-module--titleOverride--9Ho9Y";